import { Typography } from "@material-ui/core"
import Container from "@material-ui/core/Container"
import Paper from "@material-ui/core/Paper"
import { graphql, PageProps } from "gatsby"
import React from "react"
import styled from "styled-components"
import ContactButton from "../components/base/contactButton"
import Layout from "../components/base/layout"
import Center from "../components/base/layout/center"
import Spacer from "../components/base/layout/spacer"
import WpContent from "../components/base/layout/wpContent"
import ContactForm from "../components/contactForm"
import HeroImage from "../components/heroImage"
import SEO from "../components/SEO"
import { TemplatePageQuery } from "./__generated__/TemplatePageQuery"

const TextWrapper = styled(Paper)`
  padding: ${({ theme }) =>
    `${theme.spacing(4)}px ${theme.spacing(8)}px ${theme.spacing(6)}px`};

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    padding-left: ${({ theme }) => theme.spacing(6)}px;
    padding-right: ${({ theme }) => theme.spacing(6)}px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
    padding-left: ${({ theme }) => theme.spacing(2)}px;
    padding-right: ${({ theme }) => theme.spacing(2)}px;
  }
`

const Page: React.FC<PageProps<TemplatePageQuery>> = ({ data: { wpPage } }) => {
  const { showheroimage, heroimage } = { ...wpPage?.pageFields }
  const { content, addContact, seo } = { ...wpPage }
  const showContactButton = Boolean(addContact?.addcontact)
  const showContactForm = Boolean(addContact?.addcontactform)

  return (
    <>
      <SEO seo={seo} />
      {showheroimage ? (
        <HeroImage
          bgImg={heroimage?.image?.localFile?.publicURL}
          light={heroimage?.overlay === "licht"}
          text={heroimage?.text}
          opacity={heroimage?.opacity}
        />
      ) : null}
      <Layout>
        <Container maxWidth="md" disableGutters>
          <Spacer />
          <TextWrapper>
            <Typography
              component={WpContent}
              dangerouslySetInnerHTML={{ __html: String(content) }}
            />
            {showContactButton ? (
              <>
                <Spacer minHeight={32} />
                <Center>
                  <ContactButton />
                </Center>
              </>
            ) : null}
          </TextWrapper>

          {showContactForm ? (
            <>
              <Spacer />
              <Center>
                <ContactForm />
              </Center>
            </>
          ) : null}

          <Spacer minHeight={90} />
        </Container>
      </Layout>
    </>
  )
}

export default Page

export const query = graphql`
  fragment SEO on WpPostTypeSEO {
    metaDesc
    title
    opengraphUrl
    opengraphSiteName
    opengraphTitle
    opengraphDescription
    opengraphImage {
      sourceUrl
      altText
      mediaDetails {
        height
        width
      }
    }
  }
  query TemplatePageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      content
      seo {
        ...SEO
      }
      pageFields {
        showheroimage
        heroimage {
          text
          overlay
          opacity
          image {
            localFile {
              publicURL
            }
            altText
            description
          }
        }
      }
      addContact {
        addcontact
        addcontactform
      }
    }
  }
`
